import { useMutation } from 'urql';
import { gql } from 'urql';

export const DELETE_USER_TO_GROUP_MUTATION = gql`
  mutation ($id: Int!) {
    deleteUser(id: $id) {
      success
      rowsAffected
    }
  }
`;

export interface DeleteUserToGroupVariables {
  userId: number;
}

export interface DeleteUserToGroupResponse {
  deleteUserFromGroup: {
    rowsAffected: number;
    success: boolean;
  };
}

export const useFetchDeleteUser = () => {
  return useMutation(DELETE_USER_TO_GROUP_MUTATION);
};
