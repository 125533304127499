import { anatomy } from '@shared/tokens/anatomy.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  settingsPopup: {
    position: 'fixed',
    top: 0,
    left: '-100%',
    height: '100%',
    width: '480px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    transition: 'left 0.3s ease',
    transform: 'translateX(0%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  settingsPopupVisible: {
    left: '0%',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease',
  },
  overlayVisible: {
    opacity: 1,
    pointerEvents: 'all',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '480px',
    height: '64px',
    padding: '16px 24px',
    gap: anatomy['size-8'],
    marginTop: 'auto',
  },

  headerPopup: {
    width: '480px',
    height: '64px',
    padding: '16px 24px',
  },
  segmentContainer: {
    display: 'flex',
    width: '480px',
    height: '100%',
    padding: '0px 24px',
  },
  segment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '480px',
    height: '44px',
    padding: '12px 16px',
    backgroundColor: colors.brandGhostBackground,
    borderRadius: anatomy['size-8'],
    fontSize: typography['fontSizeCaption-1'],
  },
});
