import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import React, { useEffect, useState } from 'react';
import FirstStepForm from '@entities/first-step-form/index.tsx';
import SecondStepForm from '@entities/second-step-form/index.tsx';
import { CreateEventFormValues } from '@shared/types/types.ts';
import { Button } from '@sminex/sminex-uikit';
import { useNavigate } from 'react-router-dom';
import { useAddEvent } from '@features/api/use-create-event.ts';
import { useAddSegment } from '@features/api/use-create-segment.ts';
import { useCreateUser } from '@features/api/use-create-user.ts';
import { useCreateTickets } from '@features/api/use-create-tickets.ts';
import { useToast } from '@shared/hooks/useToast.ts';
import { formatLocalDate } from '@shared/libs/format-local-date.ts';

interface CreateEventFormProps {
  currentStep: number;
  nextStepHandle: () => void;
  prevStepHandle: () => void;
  selectedDate: Date[];
}

const CreateEventForm: React.FC<CreateEventFormProps> = ({
  currentStep,
  nextStepHandle,
  prevStepHandle,
  selectedDate,
}) => {
  const [eventFormValues, setEventFormValues] = useState<CreateEventFormValues>({
    name: '',
    date: selectedDate,
    file: null,
    segments: [],
  });

  const onChangeDateHandler = ([startDate, endDate]: [Date | null, Date | null]) => {
    setEventFormValues({
      ...eventFormValues,
      date: [startDate === null ? new Date() : startDate, endDate === null ? new Date() : endDate],
    });
  };

  const onFileChange = (files: File) => {
    setEventFormValues({ ...eventFormValues, file: files });
  };

  const navigate = useNavigate();

  const { addEvent, error: eventError } = useAddEvent();
  const { addSegment, error: segmentError } = useAddSegment();
  const { createUser, error: userError } = useCreateUser();
  const { createTickets, error: ticketsError } = useCreateTickets();
  const { showToast } = useToast();

  useEffect(() => {
    switch (true) {
      case !!eventError:
        showToast('Ошибка при создании мероприятия', 'error');
        break;
      case !!segmentError:
        showToast('Ошибка при создании сегмента', 'error');
        break;
      case !!userError:
        showToast('Ошибка при создании внешнего пользователя', 'error');
        break;
      case !!ticketsError:
        showToast('Ошибка при добавлении участников', 'error');
        break;
      default:
        break;
    }
  }, [eventError, segmentError, userError, ticketsError]);

  const createEventHandler = async () => {
    if (!eventFormValues.name.trim()) {
      showToast('Название мероприятия не может быть пустым', 'success');
      return;
    }
    try {
      const variables = {
        name: eventFormValues.name,
        dateStart: formatLocalDate(new Date(eventFormValues.date[0]), 'T00:00:00'),
        dateEnd: formatLocalDate(new Date(eventFormValues.date[1]), 'T23:59:59'),
        settings: {
          showFioInApp: false,
        },
      };

      const response = await addEvent(variables);

      if (response.error) {
        throw new Error(`Error: ${response.error.message}`);
      }

      navigate('/');
      if (eventError) {
        return;
      }
      const eventId = response.data.createEvent.id;

      if (eventFormValues.segments) {
        for (const segment of eventFormValues.segments) {
          const result = await addSegment({ title: segment.name, eventId });
          const usersId = [];
          if (segment.freelanceEmployee.length > 0) {
            for (const onefreelanceEmployee of segment.freelanceEmployee) {
              const user = await createUser(onefreelanceEmployee);
              usersId.push(user.data.createUser.id);
            }
          }
          usersId.push(...segment.companiesStructureList);
          await createTickets({
            usersId,
            eventId,
            segmentId: result.data.createTicketSegment.id,
          });
        }
      } else {
        showToast('In response data undefined', 'error');
      }
    } catch (error) {
      showToast('Error --->:', 'error');
    }
  };

  const formSteps = [
    <FirstStepForm
      eventFormValues={eventFormValues}
      onChangeDate={onChangeDateHandler}
      onFileChange={onFileChange}
      onChangeName={(value) => setEventFormValues({ ...eventFormValues, name: value })}
    />,
    <SecondStepForm setEventFormValues={setEventFormValues} eventFormValues={eventFormValues} />,
  ];

  return (
    <>
      <div>
        <p {...stylex.props(styles.title)}>Создать мероприятие</p>
        <form {...stylex.props(styles.form)}>{formSteps[currentStep]}</form>
      </div>
      <div {...stylex.props(styles.navigation)}>
        <Button settings={{ width: '96px' }} variant="secondary" onClick={prevStepHandle}>
          <p {...stylex.props(styles.btnText, styles.prevBtn)}>Назад</p>
        </Button>
        {currentStep !== 1 ? (
          <Button settings={{ width: '96px' }} onClick={nextStepHandle}>
            <p {...stylex.props(styles.btnText)}>Далее</p>
          </Button>
        ) : (
          <Button variant="accent" settings={{ width: '96px' }} onClick={createEventHandler}>
            <p {...stylex.props(styles.btnText)}>Создать</p>
          </Button>
        )}
      </div>
    </>
  );
};

export default CreateEventForm;
