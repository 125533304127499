import { Button, PreloaderCircular } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import ExcelIcon from './lib/ui/excel-icon.tsx';
import ButtonWithPlus from '@shared/ui/button-with-plus/index.tsx';
import useUploadFileDnd from '@shared/hooks/useUploadFileDnd.ts';
import { useEffect, useRef, useState } from 'react';
import { ModalSegment } from '@features/modal-segment/index.tsx';
import * as XLSX from 'xlsx';

interface WithExcelProps {
  onFileChange: (file: File) => void;
  segmentId: number;
  eventId: number;
}

interface IUserData {
  id: number;
  name: string;
  surname: string;
  email: string;
  phone: string;
}
interface ITicketResult {
  id: number;
  status: string;
}

interface UploadedData {
  errorUsers: number;
  existingUsers: number;
  errorUsersData: IUserData[];
  existingUsersData: IUserData[];
  ticketResults: ITicketResult[];
}

const WithExcel: React.FC<WithExcelProps> = ({ onFileChange, segmentId, eventId }) => {
  const [uploadedData, setUploadedData] = useState<UploadedData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { handleDragLeave, handleDragOver, setDragActive } = useUploadFileDnd();
  useEffect(() => {}, [uploadedData]);

  const excelInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    excelInputRef?.current?.click();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const file = event.dataTransfer.files?.length && event.dataTransfer.files[0];

    if (file) {
      const { size } = file;
      const maxSize = 1.5 * 1024 * 1024;
      if (size > maxSize) return;
      if (excelInputRef.current) {
        excelInputRef.current.files = event.dataTransfer.files;
      }
      onFileChange(file);
      uploadFile(file);
    }
  };
  const resetToInitialState = () => {
    setUploadedData(null);
    setIsLoading(false);
    setHasError(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFileChange && event.target.files?.length) {
      const file = event.target.files[0];
      onFileChange(file);
      uploadFile(file);
    }
  };

  const uploadFile = async (file: File) => {
    setIsLoading(true);
    setHasError(false);
    const formData = new FormData();
    formData.append('file', file);

    const url = `${import.meta.env.VITE_API_URL}/api/v1/csv/upload?eventId=${eventId}&segmentId=${segmentId}&ticketChecked=false&ticketInside=false&ticketActive=true&ticketRegistration=false`;

    try {
      const res = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!res.ok) {
        setHasError(true); // Если запрос не успешен
        return;
      }

      const responseData = await res.json();
      setUploadedData(responseData);
    } catch (error) {
      console.error('Ошибка при загрузке файла:', error);
      setHasError(true); // Устанавливаем ошибку при исключении
    }

    setIsLoading(false);
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/v1/csv/template`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      const data = await response.json();
      const headersString = data.template; // Пример: 'Фамилия;Имя;Отчество;Номер телефона;E-mail;комментарий'

      // Преобразуем строку заголовков в массив
      const headersArray = headersString.split(';');

      // Создаем объект данных для Excel
      const worksheetData = [headersArray];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');

      // Генерируем файл Excel и инициируем его скачивание
      XLSX.writeFile(workbook, 'шаблон.xlsx');
    } catch (error) {
      console.error('Ошибка при скачивании шаблона:', error);
    }
  };

  return (
    <div {...stylex.props(styles.withExcelBase)}>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PreloaderCircular />
        </div>
      ) : hasError ? (
        <div style={{ color: 'red', textAlign: 'center' }}>
          Произошла ошибка при загрузке файла. Пожалуйста, попробуйте еще раз.
        </div>
      ) : uploadedData ? (
        <ModalSegment uploadedData={uploadedData} onBack={resetToInitialState} /> // Отображаем ModalSegment после успешной загрузки
      ) : (
        <>
          <div {...stylex.props(styles.steps)}>
            <p {...stylex.props(styles.stepsText)}>Шаг 1 *</p>
            <Button variant="secondary" size="small" settings={{ width: '164px' }} onClick={handleDownloadTemplate}>
              <p {...stylex.props(styles.textBtn)}>Скачать шаблон.xlsx</p>
            </Button>
          </div>
          <div {...stylex.props(styles.steps)}>
            <p {...stylex.props(styles.stepsText)}>Шаг 2 *</p>
            <div
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              {...stylex.props(styles.excelInput())}
            >
              <ExcelIcon />
              <div {...stylex.props(styles.excelText)}>
                Импортируйте файл Excel с предварительно заполненными участниками
              </div>
              <input type="file" ref={excelInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
              <ButtonWithPlus labelText="Импортировать" handleClick={handleClick} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WithExcel;
