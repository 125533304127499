import { FC, useEffect, useState } from 'react';
import { Button, PreloaderCircular, Switcher } from '@sminex/sminex-uikit';
import stylex from '@stylexjs/stylex';
import { styles } from './styles'; // Стили для попапа
import useGetSettingsByEventId from '@features/api/use-get-settings';
import { useParams } from 'react-router-dom';
import useUpdateSetting from '@features/api/use-update-settings';
import { useToast } from '@shared/hooks/useToast';

interface SettingsPopupProps {
  isVisible: boolean;
  onClose: () => void;
}

export const SettingsPopup: FC<SettingsPopupProps> = ({ isVisible, onClose }) => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error } = useGetSettingsByEventId(Number(id));
  const { updateSetting } = useUpdateSetting();
  const [isSwitcherActive, setSwitcherActive] = useState<boolean>(false);

  const { showToast } = useToast();

  useEffect(() => {
    if (error) {
      showToast('Ошибка при получении данных', 'error');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setSwitcherActive(data.showFioInApp);
    }
  }, [data]);

  useEffect(() => {
    if (isVisible && data) {
      setSwitcherActive(data.showFioInApp);
    }
  }, [isVisible, data]);

  const handleSwitcherChange = async () => {
    const newState = !isSwitcherActive;
    setSwitcherActive(newState);

    try {
      const response = await updateSetting(Number(id), { showFioInApp: newState });

      if (response.error) {
        showToast('Ошибка выполнения мутации', 'error');
      }
    } catch (error) {
      showToast('Ошибка при получении данных', 'error');
    }
  };

  const handleSave = () => {
    onClose();
  };

  if (isLoading) return <PreloaderCircular />;
  if (error) return <p>Ошибка: {error.message}</p>;

  return (
    <>
      <div {...stylex.props([styles.overlay, isVisible && styles.overlayVisible])} onClick={onClose} />

      <div {...stylex.props([styles.settingsPopup, isVisible && styles.settingsPopupVisible])}>
        <h2 {...stylex.props(styles.headerPopup)}>Настройки</h2>
        <div {...stylex.props(styles.segmentContainer)}>
          <div {...stylex.props(styles.segment)}>
            <p>Показывать ФИО при регистрации билета</p>
            <Switcher size="small" isActive={isSwitcherActive} changeFunction={handleSwitcherChange} />
          </div>
        </div>
        <div {...stylex.props(styles.navigation)}>
          <Button size="small" settings={{ width: '96px' }} variant="secondary" onClick={onClose}>
            <p>Отмена</p>
          </Button>
          <Button
            size="small"
            variant="accent"
            settings={{ width: '96px', background: '#1D252D' }}
            onClick={handleSave}
          >
            <p>Сохранить</p>
          </Button>
        </div>
      </div>
    </>
  );
};
