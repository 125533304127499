import stylex from '@stylexjs/stylex';

import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

export const styles = stylex.create({
  container: {
    backgroundColor: colors.primaryBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '64px 80px',
    minHeight: '100vh',
    gap: '24px',
  },

  base: {
    width: '954px',
    fontFamily: fonts.din2014Regular,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  header: {
    padding: 0,
    color: colors.primaryText,
  },

  row: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  column: { display: 'flex', flexDirection: 'column', alignItems: 'start' },

  switcherLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.primaryText,
  },
  settingsLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.accentText,
    borderRadius: '8px',
  },

  settingsControl: {
    display: 'flex',
    cursor: 'pointer',
    padding: '8px 12px',
    borderRadius: '8px',
    ':hover': {
      background: 'var(--background-brand-ghost, #F8F6F2)',
    },
  },

  dateLabel: {
    fontSize: '14px',
    fonWeight: '400',
    paddingLeft: '16px',
    color: colors.ghostText,
  },
  tabs: {
    width: 'auto',
  },
  menuItem: {
    color: colors.primaryText,
    fontSize: '14px',
  },

  imageWrapper: {
    marginTop: '24px',
    width: '300px',
    height: '210px',
    borderRadius: '8px',
    border: `1px solid ${colors.ghostBorder}`,
  },

  editButton: {
    paddingTop: '10px',
    color: colors.accentText,
    fontSize: '12px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },

  settingsPopup: {
    position: 'fixed',
    top: 0,
    left: '-100%',
    height: '100%',
    width: '480px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    transition: 'left 0.3s ease',
    transform: 'translateX(0%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  settingsPopupVisible: {
    left: '0%',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease',
  },
  overlayVisible: {
    opacity: 1,
    pointerEvents: 'all',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '480px',
    height: '64px',
    padding: '16px 24px',
    gap: '8px',
    marginTop: 'auto',
  },

  headerPopup: {
    width: '480px',
    height: '64px',
    padding: '16px 24px',
  },
  segmentContainer: {
    display: 'flex',
    width: '480px',
    height: '100%',
    padding: '0px 24px',
  },
  segment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '480px',
    height: '44px',
    padding: '12px 16px',
  },
});
