import { fonts } from '@shared/tokens/fonts.stylex';
import { anatomy } from '@shared/tokens/anatomy.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { typography } from '@shared/tokens/typography.stylex';

export const styles = stylex.create({
  addSegmentBtn: {
    paddingTop: '16px',
  },
  base: {
    display: 'flex',
    justifyContent: 'center',
  },
  textBtn: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
    textAlign: 'left',
  },
  steps: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  excelInput: () => ({
    width: '546px',
    height: '152px',
    padding: '32px 16px 32px 16px',
    gap: '8px',
    borderRadius: '8px 0px 0px 0px',
    border: '1px solid',
    borderColor: colors.tertiaryBorder,
    opacity: '0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  excelText: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: anatomy['size-12'],
    textAlign: 'left',
    color: colors.tertiaryText,
  },
  withExcelBase: {
    paddingTop: '24px',
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
  },
  backBtn: {},
  cancelBtn: {
    color: colors.primaryText,
  },
  acceptBtn: {
    color: colors.primaryInverseText,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
    gap: '16px',
    padding: '16px',
    width: '546px',
    height: '290px',
    background: colors.ghostBackground,
    borderRadius: anatomy.borderRadiusM,
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '36px',
    paddingTop: '16px',
    ':not(#__unused__) > div > div': {
      height: '16px',
    },
  },
  stepsText: {
    color: colors.secondaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: '12px',
    textAlign: 'left',
  },
  flex: {
    display: 'flex',
    gap: '16px',
    ':not(#__unused__) > div > div': {
      height: '16px',
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    marginTop: '24px',
  },
  checkboxWithName: {
    display: 'flex',
    padding: '8px 12px',
    alignItems: 'flex-start',

    gap: '8px',
    width: '522px',

    alignSelf: 'stretch',
    boxSizing: 'content-box',
    cursor: 'pointer',
  },
  participant: {
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxHeight: '40px',
    color: colors.primaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontStyle: 'normal',
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
  },
  inputWithSelectedParticipant: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },
  input: {
    ':not(#__unused__) > div > div': {
      height: '16px',
    },
  },
  blockAllList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  allList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    maxHeight: '300px',
    width: '546px',
  },
  position: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontStyle: 'normal',
    fontWeight: typography['fontWeighRegular'],
    lineHeight: typography['lineHeightBody-3'],
    color: colors['secondaryText'],
    textAlign: 'center',
  },

  reminderFreeForm: {
    marginTop: '8px',
    color: colors.errorText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontStyle: 'normal',
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
  },
});
