import { useQuery, gql } from 'urql';

const GET_SETTINGS_BY_EVENT_ID = gql`
  query GetSettingsByEventId($eventId: Int!) {
    getSettingsByEventId(eventId: $eventId) {
      id
      eventId
      showFioInApp
    }
  }
`;

const useGetSettingsByEventId = (eventId: number) => {
  const [result] = useQuery({
    query: GET_SETTINGS_BY_EVENT_ID,
    variables: { eventId },
    // requestPolicy: 'network-only',
  });

  //   console.log('Variables:', { eventId });
  //   console.log('data:', result.data);

  const { data, fetching, error } = result;
  //   console.log('result', result);

  return {
    data: data?.getSettingsByEventId,
    isLoading: fetching,
    error,
  };
};

export default useGetSettingsByEventId;
