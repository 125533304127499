const SortTriangleIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Sort-triangle">
        <path
          id="Vector"
          d="M9.41066 4.75592L6.42251 7.74408C5.89754 8.26905 6.26934 9.16667 7.01176 9.16667H12.9881C13.7305 9.16667 14.1023 8.26905 13.5773 7.74408L10.5892 4.75592C10.2637 4.43049 9.7361 4.43049 9.41066 4.75592Z"
          fill="#BBBEC0"
        />
        <path
          id="Vector_2"
          d="M9.41066 15.2441L6.42251 12.2559C5.89754 11.731 6.26934 10.8333 7.01176 10.8333H12.9881C13.7305 10.8333 14.1023 11.731 13.5773 12.2559L10.5892 15.2441C10.2637 15.5695 9.7361 15.5695 9.41066 15.2441Z"
          fill="#BBBEC0"
        />
      </g>
    </svg>
  );
};

export default SortTriangleIcon;
