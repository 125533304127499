import { useMutation } from 'urql';
import { gql } from 'urql';

export const CREATE_EVENT_ORGANIZER_MUTATION = gql`
  mutation CreateEventOrganizer($input: CreateEventOrganizer!) {
    createEventOrganizer(input: $input) {
      uuid
      email
      login
      position
      phone
      firstName
      lastName
      avatar
      confirmed
      sortNumber
      active
      surname
      source
      isIndividual
      departamentUuid
      createdAt
      updatedAt
      id
    }
  }
`;

export interface CreateEventOrganizerVariables {
  input: {
    eventId: number;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
  };
}

export interface CreateEventOrganizerResponse {
  createEventOrganizer: {
    uuid: string;
    email: string;
    login: string;
    position: string;
    phone: string;
    firstName: string;
    lastName: string;
    avatar?: string;
    confirmed?: boolean;
    sortNumber?: number;
    active?: boolean;
    surname?: string;
    source?: string;
    isIndividual?: boolean;
    departamentUuid?: string;
    createdAt: string;
    updatedAt: string;
    id: number;
  };
}

export const useFetchAddOrganizer = () => {
  return useMutation<CreateEventOrganizerResponse, CreateEventOrganizerVariables>(CREATE_EVENT_ORGANIZER_MUTATION);
};
