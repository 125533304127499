import * as stylex from '@stylexjs/stylex';
import { colors } from '../../shared/tokens/colors.stylex';
import { typography } from '../../shared/tokens/typography.stylex';
import { fonts } from '../../shared/tokens/fonts.stylex';

export const styles = stylex.create({
  base: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    background: colors.primaryBackground,
    cursor: 'pointer',
    padding: '0px 24px',
  },
  section: {
    width: '100%',
  },
  open: {
    position: 'absolute',
    bottom: '71px',
    left: '263px',
    color: colors.ghostText,
    fontSize: '12px',
    fontFamily: fonts.din2014Regular,
  },
  infoSection: {
    width: '227px',
    display: 'flex',
  },
  infoWidget: {
    width: '190px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '28px',
  },
  infoText: {
    width: '227px',
    fontFamily: fonts.din2014Regular,
    fontSize: '12px',
    background: colors.primaryBackground,
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['fontSizeCaption-2'],
    color: colors.ghostText,
    height: '24px',
  },
  firstText: {
    marginTop: '-8px',
  },
  middleText: {
    paddingBottom: '4px',
  },
  lastText: {
    marginTop: '10px',
  },

  infoTitle: {
    color: colors.tertiaryText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoValue: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'flex-end',
  },
  text: {
    height: '44px',
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: '8px',
  },
  accepted: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: fonts.din2014Regular,
    fontSize: '32px',
    lineHeight: '32px',
  },
  notAccepted: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: fonts.din2014Regular,
    fontSize: '32px',
    lineHeight: '40px',
    color: colors.successBorder,
  },
  unrecongnized: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: fonts.din2014Regular,
    fontSize: '32px',
    lineHeight: '40px',
    color: colors.errorBorder,
  },

  progressBarContainer: {
    width: '100%',
    height: '24px',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontFamily: fonts.din2014Regular,
    padding: '6px 24px',
  },
  total: (width: number) => ({
    width: `${width}px`,
    backgroundColor: ' #F5F5F5',
  }),
  recognized: (width: number) => ({
    width: `${width}px`,
    backgroundColor: colors.successBorder,
  }),
  unrecognized: (width: number) => ({
    width: `${width}px`,
    backgroundColor: colors.errorBorder,
  }),

  userList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--border-tertiary, rgba(29, 37, 45, 0.10))',
    width: '432px',
  },
  userItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    borderBottom: `1px solid red`,
  },

  userName: {
    display: 'flex',
    width: '144px',
    minWidth: '144px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    fontFamily: fonts.din2014Regular,
    color: colors.primaryText,
  },
  userEmail: {
    display: 'flex',
    width: '144px',
    minWidth: '144px',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '10px',
  },
  userPhone: {
    flex: '1 1 33%',
    textAlign: 'right',
  },
  userTable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
    width: '480px',
    height: '256px',
    color: colors.primaryText,
    backgroundColor: colors.primaryBackground,
    fontFamily: fonts.din2014Regular,
    fontSize: '14px',
  },
  userTableText: {
    color: 'var(--foreground-tertiary, #8E9296)',
  },
  contactHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    fontSize: '14px',
    borderBottom: '1px solid var(--border-tertiary, rgba(29, 37, 45, 0.10))',
    width: '432px',
    height: '36px',
  },
  contactInfo: {
    display: 'flex',
    width: '144px',
    height: '36px',
    minWidth: '144px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    rowGap: '10px',
    flexWrap: 'wrap',
  },
  contactItem: {
    display: 'flex',
    minWidth: '36px',
    padding: '8px var(--space-16, 16px)',
    alignItems: 'center',
    gap: ' 8px',
    flex: '1 0 0',
    alignSelf: 'stretch',
  },
  userNameItem: {
    display: 'flex',
    padding: '8px var(--space-16, 16px)',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },
  userEmailItem: {
    display: 'flex',
    padding: '8px var(--space-16, 16px)',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },
  userPhoneItem: {
    display: 'flex',
    padding: '8px var(--space-16, 16px)',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
  },

  contactType: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    height: '256px',
    padding: '0px 24px',
  },

  userSelect: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    paddingBottom: '8px',
    fontSize: '12px',
  },
  scrollContainer: {
    overflowY: 'auto',
    width: '100%',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    alignItems: 'center',
    height: '66px',
    padding: ' 24px ',
  },
  contactTypeStatic: {
    padding: '0px 24px',
  },
});
