import { gql, useMutation } from 'urql';

// Определение мутации
export const CREATE_EVENT = gql`
  mutation CreateEvent($name: String!, $dateStart: String!, $dateEnd: String!, $settings: Boolean!) {
    createEvent(input: { name: $name, dateStart: $dateStart, dateEnd: $dateEnd }, settings: $settings) {
      id
      name
      description
      confirmed
      active
      image
      dateStart
      dateEnd
      createdAt
      updatedAt
    }
  }
`;

// Хук для использования мутации
export const useAddEvent = () => {
  const [{ data, error }, executeMutation] = useMutation(CREATE_EVENT);

  const addEvent = (variables: { name: string; dateStart: string; dateEnd: string }) => {
    return executeMutation(variables);
  };

  return { data, error, addEvent };
};
