import { gql, useQuery } from 'urql';
export const USERS_QUERY = gql`
  query GetUsers {
    getUsers(showEventOrganizer: false) {
      data {
        id
        uuid
        email
        phone
        firstName
        lastName
        position
        avatar
        confirmed
        departamentUuid
        createdAt
        updatedAt
      }
    }
  }
`;

export interface IUser {
  id: number;
  uuid: string;
  email: string;
  login: string;
  phone: string;
  firstName: string;
  lastName: string;
  position: string;
  avatar: string;
  confirmed: boolean;
  departamentUuid: string;
  createdAt: string;
  updatedAt: string;
  checked?: boolean;
}

export interface IUsersResponse {
  getUsers: {
    data: IUser[];
  };
}

export const useFetchUsers = (showEventOrganizer = false) => {
  const [{ error, fetching, data }] = useQuery<IUsersResponse>({
    query: USERS_QUERY,
    variables: { showEventOrganizer },
  });
  const userData = data?.getUsers && data.getUsers;
  return { error, userData, fetching };
};
