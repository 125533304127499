import { Button } from '@sminex/sminex-uikit';
import stylex from '@stylexjs/stylex';
import { FC, useState } from 'react';
import { AddButton } from '../../features/add-button';
import { Chip } from '@shared/chip';
import { IconButton } from '@shared/ui/icon-button';
import { TableIcon, TreeIcon } from '@shared/ui/icons';
import { SegmentCard } from '@features/segment-card';
import { styles } from './styles';
import { AddParticipantsModal } from '@entities/add-participants-modal';
import { ISegmentParticipants } from '@entities/add-participants-modal';
import { ITicket } from '@pages/event/api/useFetchGetTicketsSegment';
export interface ISegmensTabProps {
  segments: ISegmentParticipants[];
  onAddSegment: () => void;
  onDeleteSegment: (segment: ISegmentParticipants) => void;
  sendInvitations?: (invations: string[]) => void;
  sendTickets: (users: number[], tickets?: number[]) => void;
}

const initialSegment: ISegmentParticipants = {
  id: Date.now(),
  eventId: 0,
  title: 'Название сегмента',
  companiesStructureList: {},
  freelanceEmployee: [],
};

export interface IInvitedParticipants {
  userId: number;
  ticketId: number;
}

export const SegmensTab: FC<ISegmensTabProps> = ({ segments, onDeleteSegment, sendTickets, onAddSegment }) => {
  const [checkedUsers, setCheckedUsers] = useState<{ [key: number]: number }>({});
  const [invitedParticipants, setInvitedParticipants] = useState<IInvitedParticipants[]>([]);
  const [toggleParticipantsView, setToggleParticipantsView] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [update, setUpdate] = useState<boolean>(false);

  const [currentSegment, setCurrenSegment] = useState<ISegmentParticipants>(structuredClone(initialSegment));

  const openModaleHandler = (segment: ISegmentParticipants, users: IInvitedParticipants[]) => {
    setInvitedParticipants(users);
    setIsOpenModal(true);
    setUpdate(!update);
    setCurrenSegment(segment);
  };

  const toggleAccordionHandler = (isOpen: boolean) => {
    if (isOpen) {
      setUpdate(!update);
    }
  };

  const viewButtons = [
    <IconButton
      icon={<TableIcon />}
      settings={{ padding: '0 20px 0 20px' }}
      size="small"
      onClick={() => setToggleParticipantsView(1)}
    >
      Таблица
    </IconButton>,
    <IconButton
      icon={<TreeIcon />}
      settings={{ padding: '0 20px 0 20px' }}
      size="small"
      onClick={() => setToggleParticipantsView(0)}
    >
      Дерево
    </IconButton>,
  ];

  const toggleUser = (ticket: ITicket) => {
    const isChecked = !checkedUsers[ticket.id];
    if (isChecked) {
      setCheckedUsers((prev) => {
        return { ...prev, [ticket.id]: ticket.user.id };
      });
      return;
    }
    const newUsers = { ...checkedUsers };
    delete newUsers[ticket.id];
    setCheckedUsers(newUsers);
  };

  const toggeAllUsers = (users: { userId: number; ticketId: number }[], isChecked: boolean) => {
    if (isChecked) {
      const newUsers: { [key: number]: number } = {};
      for (const user of users) {
        newUsers[user.ticketId] = user.userId;
      }
      setCheckedUsers((prev) => {
        return { ...prev, ...newUsers };
      });
      return;
    }
    const newUsers = { ...checkedUsers };
    for (const user of users) {
      delete newUsers[user.ticketId];
    }
    setCheckedUsers(newUsers);
  };

  const sendTicketHandler = () => {
    const users = Object.values(checkedUsers);
    const tickets = Object.keys(checkedUsers).map(Number);
    sendTickets(users, tickets);
  };

  return (
    <>
      <AddParticipantsModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        segment={{ title: currentSegment.title, id: currentSegment.id, eventId: currentSegment.eventId || 0 }}
        invitedParticipants={invitedParticipants}
      />

      <div {...stylex.props(styles.actions)}>
        <div {...stylex.props(styles.qrButtonsPanel)}>
          <Button settings={{ padding: '0 20px 0 20px' }} variant="secondary" size="small" onClick={sendTicketHandler}>
            <p {...stylex.props(styles.label)}>Отправить билеты выбранным</p>
            <Chip text={Object.values(checkedUsers).filter((item) => item).length} />
          </Button>
        </div>
        {/* убрали пока */}
        {false && viewButtons[toggleParticipantsView]}
      </div>
      {segments?.map((segment) => (
        <div key={segment.id} {...stylex.props(styles.segment)}>
          <SegmentCard
            setCheckedUsers={setCheckedUsers}
            onToggle={(isOpen) => toggleAccordionHandler(isOpen)}
            segment={segment}
            checkedUsers={checkedUsers}
            toggleUser={toggleUser}
            toggleAllUsers={toggeAllUsers}
            onDeleteSegment={onDeleteSegment}
            setInvitedParticipants={setInvitedParticipants}
            onAddSegment={(users) => {
              openModaleHandler(segment, users);
            }}
          />
        </div>
      ))}
      <AddButton onClick={onAddSegment}>Добавить сегмент</AddButton>
    </>
  );
};
