import { useMutation } from 'urql';
import { gql } from 'urql';

// Исправленный GraphQL запрос
const SEND_MAIL_MUTATION = gql`
  mutation SendMail($info: MailInfo!) {
    sendMail(info: $info) {
      id
      error
      from
    }
  }
`;

interface MailUser {
  name: string;
  email: string;
}

interface MailInfo {
  from: MailUser;
  to: number[];
  subject: string;
  htmlBody: string | null;
  textBody: string | null;
}

// Хук для работы с мутацией
export const useFetchMail = () => {
  const [sendMailResult, sendMail] = useMutation(SEND_MAIL_MUTATION);

  const sendEmail = async (textBody: string, ticketId: number[]) => {
    const info: MailInfo = {
      from: { name: 'Sminex.events', email: 'events@sminex.com' },
      to: ticketId,
      subject: 'events@sminex.com',
      htmlBody: null,
      textBody,
    };

    try {
      const result = await sendMail({ info });
      if (result.error) {
        console.error('Ошибка при отправке письма:', result.error);
      } else {
        console.log('Письмо успешно отправлено:', result.data);
      }
      return result;
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      throw error;
    }
  };

  return { sendEmail, ...sendMailResult };
};
