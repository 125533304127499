import { FC, useState } from 'react';
import { styles } from './styles';
import * as stylex from '@stylexjs/stylex';
import { Button } from '@sminex/sminex-uikit';
import SortTriangleIcon from '@shared/ui/icons/sort-triangle-icon';

interface IUserData {
  id: number;
  name: string;
  email: string;
  phone: string;
  surname: string;
}

interface ITicketResult {
  id: number;
  status: string;
}

interface UploadedData {
  errorUsers: number;
  existingUsers: number;
  errorUsersData: IUserData[];
  existingUsersData: IUserData[];
  ticketResults: ITicketResult[];
}

interface IModalProps {
  uploadedData: UploadedData;
  onBack: () => void;
}

export const ModalSegment: FC<IModalProps> = ({ uploadedData, onBack }) => {
  const [showErrorUsers, setShowErrorUsers] = useState(false);
  const totalUsers = uploadedData.existingUsers + uploadedData.errorUsers; // Общее число пользователей
  const recognizedUsers = uploadedData.existingUsers; // Успешно распознанные
  const unrecognizedUsers = uploadedData.errorUsers;
  const getWidth = (value: number): number => {
    if (totalUsers * 1 === 0) return 0;
    const percentage = (value / totalUsers) * 100;
    const maxWidth = 138;
    return Math.round((maxWidth / 100) * percentage);
  };

  const getPercent = (value: number): number => {
    if (totalUsers * 1 === 0) return 0;
    return Math.round((value / totalUsers) * 100);
  };

  return (
    <>
      {!showErrorUsers ? (
        <div {...stylex.props(styles.base)}>
          <div {...stylex.props(styles.section)}>
            <div {...stylex.props(styles.infoSection)}>
              <div {...stylex.props(styles.infoText)}>
                <div {...stylex.props(styles.text, styles.firstText)}>Пользователей в файле</div>
                <div {...stylex.props(styles.text, styles.middleText)}>Успешно распознанные</div>
                <div {...stylex.props(styles.text, styles.lastText)}>Не распознанные</div>
              </div>
              <div {...stylex.props(styles.infoValue)}>
                <div {...stylex.props(styles.accepted)}>{totalUsers}</div>
                <div {...stylex.props(styles.notAccepted)}>{recognizedUsers}</div>
                <div {...stylex.props(styles.unrecongnized)}>{unrecognizedUsers}</div>
              </div>
              <div {...stylex.props(styles.open)} onClick={() => setShowErrorUsers(true)}>
                показать
              </div>
            </div>
          </div>
          <div {...stylex.props(styles.infoWidget)}>
            <div {...stylex.props(styles.progressBarContainer)}>
              <div {...stylex.props(styles.progressBar, styles.total(getWidth(totalUsers)))}>100%</div>
            </div>
            <div {...stylex.props(styles.progressBarContainer)}>
              <div {...stylex.props(styles.progressBar, styles.recognized(getWidth(recognizedUsers)))}>
                {getPercent(recognizedUsers)}%
              </div>
            </div>
            <div {...stylex.props(styles.progressBarContainer)}>
              <div {...stylex.props(styles.progressBar, styles.unrecognized(getWidth(unrecognizedUsers)))}>
                {getPercent(unrecognizedUsers)}%
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {uploadedData.errorUsersData.length > 0 ? (
            <div {...stylex.props(styles.userTable, styles.userTableText)}>
              <div {...stylex.props(styles.userHeader)}>
                <div {...stylex.props(styles.headerName)}>
                  <p {...stylex.props(styles.itemName)}>
                    ФИО <SortTriangleIcon />
                  </p>
                </div>
                <div {...stylex.props(styles.headerEmail)}>
                  <p {...stylex.props(styles.itemEmail)}>
                    E-mail <SortTriangleIcon />
                  </p>
                </div>
                <div {...stylex.props(styles.headerPhone)}>
                  <p {...stylex.props(styles.itemPhone)}>
                    Телефон <SortTriangleIcon />
                  </p>
                </div>
              </div>

              <div {...stylex.props(styles.userTable)}>
                {uploadedData.errorUsersData.map((user) => (
                  <div key={user.id} {...stylex.props(styles.userList)}>
                    <div {...stylex.props(styles.userName)}>
                      <p {...stylex.props(styles.userNameItem)}>
                        {user.surname} {user.name}
                      </p>
                    </div>
                    <div {...stylex.props(styles.userEmail)}>
                      <p {...stylex.props(styles.userEmailItem)}>{user.email}</p>
                    </div>
                    <div {...stylex.props(styles.userPhone)}>
                      <p {...stylex.props(styles.userPhoneItem)}>{user.phone}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p>Нет данных о нераспознанных пользователях</p>
          )}
        </div>
      )}
      <Button
        size="small"
        settings={{
          width: '96px',
          fontFamily: 'DIN-2014_Regular',
          position: 'absolute',
          bottom: '16px',
          left: '24px',
        }}
        variant="secondary"
        onClick={onBack}
      >
        <p>Назад</p>
      </Button>
    </>
  );
};
